import React from "react"
// import { graphql , useStaticQuery} from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStores } from '@hooks/useStores'
import Seo from "@c-modules/Seo"
import ContactForm from "@c-blocks/contact/ContactForm"
import css from "@css/layout/l-page.module.styl"

export default function Index({data})
{
	return(
		<>
		<Seo title="contact"/>
		<main className={css.lPage}>
			<ContactForm/>
		</main>
		</>
	)
}