import React from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import qs from 'qs'
import ModalMessage from '@c-modules/ModalMessage'
// import LoadableFallback from '@c-modules/LoadableFallback'
import { SubmitButton } from '@c-atoms/Button'

import css from "@css/project/p-form.module.styl"


const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export default function Form({formName, isDebug})
{
    const [isSending, setIsSending] = React.useState(0)//0: normal 1:sending 2:end
    // const isTest = process.env.production ? false : true//これがうまく動作してない

    const delay = 1000

    const { register, handleSubmit, watch, errors, reset, getValues, setValue } = useForm()

    const onSubmit = async (data, e) => {
        e.preventDefault()
        if (isSending !== 0) { return }
        setIsSending(1)

        console.log(data)
        const options = {
            url: "/contact/",
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(data),
        }

        // if(isDebug){
        //     setTimeout(() => {
        //         // alert(JSON.stringify( formValues, null, 2))
        //         if (isDebug === 1) {
        //             setIsSending(2)
        //             // reset()
        //         } else {
        //             setIsSending(-1)
        //         }
        //     }, delay)
        //     return
        // }

        try {
            await timeout(delay)
            await axios(options)
            setIsSending(2)
            // e.target.submit()
            // setMsgSent(true)
        } catch (e) {
            console.log(e.ModalMessage)
            setIsSending(-1)
            // this.setState({
            //     errorModalMessage: e.ModalMessage,
            //     isSubmit: false
            // })
            // this.setSubmitting(false)
            // setErrMsg(e.ModalMessage)
        }
    }

    React.useEffect(()=>{
        if(isDebug){
            // setValue('company', '株式会社モモカンパニー')
            setValue('name', '桃山 太郎')
            setValue('email', 'info@marina-oficial.jp')
            setValue('tel', '123-4567-8910')
            setValue('fax', '123-4567-8910')
            setValue('subject', 'お仕事のご依頼')
            setValue('message', `はじめまして株式会モモカンパニーの桃山太郎と申します。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。
            `)
        }
    },[])

    // console.log(watch("mail")); // watch input value by passing the name of it
    return (
        <div className={css.pForm}>
            { isSending === 2 ?
                <ModalMessage
                    title="お問い合わせ<br>ありがとうございました!"
                    text={`
                        <strong>${getValues('email')}</strong>へ自動返信メールを送信しました。<br>
                        届かない場合はメールアドレスや受信設定をご確認ください。<br>
                        数日中に担当より折り返しご連絡いたします。
                        `}
                    link="/" />
                :
                null
            }
            { isSending === -1 ?
                <ModalMessage
                    title="エラーが発生したため<br>送信に失敗しました。"
                    text={`
                        間をおいてもう一度お試しいただくか、<br>
                        <strong>info@marina-official.jp</strong>までお問い合わせください。
                        `}
                    action={() => {
                        setIsSending(0)
                    }} />
                :
                null
            }
            <div className={css.form}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    name={formName}
                    method="post"
                    netlify={true}
                    data-netlify={true}
                    data-netlify-honeypot="bot-field"
                >
                    {/* netlify contact form */}
                    <input type="hidden" name="form-name" value={formName} ref={register} />
                    <input type="hidden" name="bot-field" ref={register} />
                    {/* netlify contact form */}
                    <Input
                        title="会社名/団体名*"
                        register={register({ required: true })}
                        errors={errors}
                        type="text"
                        name="company"
                        id="company"
                        placeholder="会社名/団体名を入力"
                        errorModalMessage="会社名/団体名を入力してください"
                    />
                    <Input
                        title="ご担当者名*"
                        register={register({ required: true })}
                        errors={errors}
                        type="text"
                        name="name"
                        id="name"
                        placeholder="ご担当者名を入力"
                        errorModalMessage="ご担当者名を入力してください"
                    />
                    <Input
                        title="メールアドレス*"
                        register={register({ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
                        errors={errors}
                        type="email"
                        name="email"
                        id="email"
                        placeholder="メールアドレスを入力"
                        errorModalMessage="正しいメールアドレスを入力してください"
                    />
                    <Input
                        title="電話番号*"
                        register={register({ required: true, pattern: /^\d{2,4}-?\d{2,4}-?\d{3,4}$/i })}
                        errors={errors}
                        type="tel"
                        name="tel"
                        id="tel"
                        placeholder="電話番号を入力"
                        errorModalMessage="正しい電話番号を入力してください"
                    />
                    <Input
                        title="FAX"
                        register={register({ required: false, pattern: /^\d{2,4}-?\d{2,4}-?\d{3,4}$/i })}
                        errors={errors}
                        type="tel"
                        name="fax"
                        id="fax"
                        placeholder="FAXを入力"
                        errorModalMessage="正しいFAX入力してください"
                    />
                    <Input
                        title="件名*"
                        register={register({ required: true })}
                        errors={errors}
                        type="text"
                        id="subject"
                        name="subject"
                        placeholder="件名を入力"
                        errorModalMessage="件名を入力してください"
                    />
                    <Field
                        title="お問い合わせ内容*"
                        register={register({ required: true })}
                        errors={errors}
                        name="message"
                        id="message"
                        placeholder="お問い合わせ内容を入力"
                        errorModalMessage="メッセージを入力してください"
                    />
                    <div className={css.footer}>
                        <SubmitButton type="submit">
                            {
                                isSending === 0 ? '送信' :
                                    isSending === 1 ? '...送信中' :
                                        isSending === 2 ? '送信完了' :
                                            isSending === -1 && '失敗'
                            }
                        </SubmitButton>
                    </div>
                </form>
            </div>
        </div>
    )
}

function Input({ title, register, type, errors, name, id, placeholder, errorModalMessage }) {
    return (
        <div className={css.group}>
            <div className={css.inner}>
                {title &&
                    <p className={css.label}>
                        <label for={id}>{title}</label>
                        {errors[name] && <span className={css.error}>{errorModalMessage}</span>}
                    </p>
                }
                <input
                    id={id}
                    type={type}
                    className={css.control}
                    ref={register}
                    name={name}
                    placeholder={placeholder}
                    autoComplete="off"
                />
            </div>
        </div>
    )
}


function Field({title, register, errors, name, id, placeholder, errorModalMessage }) {
    return (
        <div className={css.group}>
            <div className={css.inner}>
                {title &&
                    <p className={css.label}>
                        <label for={id}>{title}</label>
                        {errors[name] && <span className={css.error}>{errorModalMessage}</span>}
                    </p>
                }
                <textarea
                    id={id}
                    className={css.control}
                    ref={register}
                    name={name}
                    placeholder={placeholder}
                    autoComplete="off"
                    rows="8"
                />
            </div>
        </div>
    )
}