import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { Link } from "@c-atoms/Link"
// import { FluidImage, BackgroundFluidImage } from "@c-atoms/Image"
// import { Button } from "@c-atoms/Button"
import Form from "@c-modules/Form"
const css = {
    ...require("@css/layout/contact/l-contact-form.module.styl"),
}

export default function ConntactForm()
{
    return(
        <section className={css.lContactForm} id="contact">
            <div className={css.lContactForm__inner}>
                <h3 className={css.lContactForm__title}>
                    CONTACT
                </h3>
                <p className={css.lContactForm__text}>
                    お仕事のご依頼等は、お問合せフォームにて受け付けております。<br/>
                    間違ったメールアドレスを記入されますと、こちらからご返信ができません。<br />
                    メールアドレスのお間違いのないようお気をつけ下さい。お問合せより3営業日以内に、<br/>
                    こちらからの返答がない場合はお手数ですが再度お問合せ下さい。
                </p>
                <div className={css.lContactForm__form}>
                    <Form formName="marina-official.jp" />
                </div>
            </div>
        </section>
    )
}
