/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Button } from '@c-atoms/Button'
import css from "@css/project/p-modal-message.module.styl"


const Message = ({title, text, link, button = "OK", action}) => {
    const containerRef = React.useRef()
	return (
        <div className={css.pModalMessageShow} ref={containerRef}>
            <div className={css.pModalMessage__inner}>
                { title &&
                    <h1 className={css.pModalMessage__title} dangerouslySetInnerHTML={{ __html: title }}/>
                }
                { text &&
                    <div className={css.pModalMessage__text} dangerouslySetInnerHTML={{ __html: text }}/>
                }
                <div className={css.pModalMessage__footer}>
                    {!action ?
                        <Button to={link}>
                            {button}
                        </Button>
                        :
                        <Button onClick={() => {
                            containerRef.current.classList.remove(css.pModalMessageShow)
                            containerRef.current.classList.add(css.pModalMessageHide)
                            setTimeout(() => {
                                action()
                            }, 300)
                        }}>
                            {button}
                        </Button>
                    }
                </div>
            </div>
        </div>
	)
}

export default Message
